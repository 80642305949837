import * as styles from "./FeaturesTable.module.scss";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import * as React from "react";

type TableProps<T> = { columns: ColumnDef<T>[]; tableData: T[]; columnVisibility?: Record<string, boolean> };
export const FeaturesTable = <T,>(props: TableProps<T>): JSX.Element => {
  const { columns, tableData: data, columnVisibility } = props;

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
    },
  });
  return (
    <div className="tw-animate-fade-in">
      <table className={styles.featuresTable}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>{!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, idx) => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} style={{ backgroundColor: idx % 2 === 0 ? "#232222" : "none" }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id} style={{ width: "15%" }}>
                  {!header.isPlaceholder && flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};
