import { CONTAINER_STYLES, FlexibleTextDisplay, VisualHeading } from ".";
import { sentenceCaseText } from "../../Utils";
import { Heading } from "../Heading/Heading";
import { DescriptionVideo } from "./DescriptionVideo";
import { Pill } from "./Pill";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export type Video = {
  poster: { src: any };
  webM: { publicURL: any };
  mp4: { publicURL: any };
};
export interface ITemplate {
  description: { heading: string; text: string };
  tags: string[];
  keywords?: { value: string; label: string }[];
  video: {
    descriptionVideo: Video;
  };
  images: { tileImage: { src: any; alt: string }[] };
  altDescription: { heading: string; text: string; bullets: string[] };
  howToSection: {
    heading: string;
    bullets: { heading: string; text: string }[];
  };
  whatIsSection: { heading: string; text: string };
  benefitsSection: { heading: string; text: string[] };
}

export const MobileView = ({
  description,
  images,
  altDescription,
  video,
  tags,
  howToSection,
  whatIsSection,
  benefitsSection,
}: Omit<ITemplate, "keywords">): JSX.Element => {
  const whatIsSectionHeading = sentenceCaseText(whatIsSection.heading);
  const howToSectionHeading = sentenceCaseText(howToSection.heading);
  const benefitsSectionHeading = sentenceCaseText(benefitsSection.heading);
  const descriptionSectionHeading = sentenceCaseText(description.heading);
  const altDescriptionSectionHeading = sentenceCaseText(altDescription.heading);

  return (
    <section className={twMerge(CONTAINER_STYLES, "!tw-pb-[65px] !tw-pt-[74px] max-lg:!tw-flex")}>
      <div className={`tw-flex tw-w-[64%] tw-flex-col max-sm:!tw-w-[83%]`}>
        <div className="tw-pb-[25px]">
          <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
            {descriptionSectionHeading}
          </Heading>
        </div>
        <div className="tw-pb-[25px]">
          <FlexibleTextDisplay text={description.text} />
        </div>
        <GatsbyImage
          image={getImage(images.tileImage[0].src)}
          alt={images.tileImage[0].alt}
          className="lightBorder tw-aspect-video tw-w-[100%] tw-rounded-lg !tw-pb-[47px] !tw-shadow-default "
        />
        <div className="tw-pb-[25px] tw-pt-[47px] ">
          <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
            {altDescriptionSectionHeading}
          </Heading>
        </div>
        <FlexibleTextDisplay text={altDescription.text} />
        {altDescription.bullets && altDescription.bullets.length > 0 && (
          <ul className="tw-list-inside tw-list-disc ">
            {altDescription.bullets.map(bullet => (
              <li key={bullet} className="tw-text-[18px]">
                {bullet}
              </li>
            ))}
          </ul>
        )}
        <DescriptionVideo descriptionVideo={video.descriptionVideo} />
        <div className="tw-pb-[25px] tw-pt-[47px]">
          <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
            Template formats
          </Heading>
        </div>

        {tags && (
          <div className="flex-wrap !tw-flex !tw-gap-4">
            {tags.map(tag => (
              <Pill label={tag} key={tag} />
            ))}
          </div>
        )}
        {howToSection && (
          <>
            <div className="tw-pb-[25px] tw-pt-[47px]">
              <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
                {howToSectionHeading}
              </Heading>
            </div>
            {howToSection.bullets.map((bullet, index) => (
              <div key={index} className={index < howToSection.bullets.length - 1 ? `tw-mb-8` : `tw-mb-0`}>
                <FlexibleTextDisplay
                  heading={bullet.heading}
                  text={bullet.text}
                  classNames={{
                    heading: "!tw-pb-0 !tw-font-bold !tw-text-[18px]",
                    text: index < howToSection.bullets.length - 1 ? `tw-mb-4` : `tw-mb-0`,
                  }}
                />
              </div>
            ))}
          </>
        )}
        {whatIsSection && (
          <>
            <div className="tw-pb-[25px] tw-pt-[47px]">
              <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
                {whatIsSectionHeading}
              </Heading>
            </div>
            <FlexibleTextDisplay text={whatIsSection.text} />
          </>
        )}
        {benefitsSection && (
          <>
            <div className="tw-pb-[25px] tw-pt-[47px]">
              <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
                {benefitsSectionHeading}
              </Heading>
            </div>
            <FlexibleTextDisplay
              text={benefitsSection.text}
              classNames={{
                text: "!tw-mb-4",
              }}
            />
          </>
        )}
      </div>
    </section>
  );
};
