import { SectionHeading } from "../SectionHeading/SectionHeading";
import { Paragraph } from "../Text";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export const Callouts = ({ heading }: { heading?: string }): JSX.Element => {
  const fontSizeOverides = `max-sm:!tw-text-base mx-md-alt:!tw-text-[23px] min-xl:!tw-text-[26px]`;
  return (
    <section className="container tw-max-sm:!tw-pt-4 tw-max-md:tw-pt-16 text-center tw-pb-20">
      {heading && (
        <div className="tw-pb-8">
          <SectionHeading>{heading}</SectionHeading>
        </div>
      )}

      <div className="tw-grid tw-grid-cols-3 tw-gap-x-[7%] tw-gap-y-14 tw-pl-[20px]  tw-pr-[20px] tw-text-left max-md:tw-grid-cols-1">
        <div data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease-out">
          <div className="w-full tw-aspect-[3/2] tw-overflow-hidden tw-rounded-[16px] max-md:tw-h-auto">
            <StaticImage
              src="../../../static/media/home/feature1.png"
              className="tw-h-full tw-w-full"
              alt="Add your text and media with the WYSIWYG web editor"
              aria-hidden="true"
              placeholder="blurred"
              width={750}
              height={500}
              objectFit="contain"
            />
          </div>
          <div className="!tw-mb-4 !tw-mt-9">
            <SectionHeading>No code required</SectionHeading>
          </div>
          <Paragraph className={fontSizeOverides}>
            Say goodbye to developer bottlenecks and escalating costs with our human-friendly story editor.
          </Paragraph>
        </div>
        <div data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease-out">
          <div className="w-full tw-aspect-[3/2] tw-overflow-hidden tw-rounded-[16px] max-md:tw-h-auto">
            <StaticImage
              src="../../../static/media/home/feature2.png"
              className="tw-h-full tw-w-full"
              alt="Beautiful, full-screen layouts for desktop and mobile"
              aria-hidden="true"
              placeholder="blurred"
              width={750}
              height={500}
              objectFit="contain"
            />
          </div>
          <div className="!tw-mb-4 !tw-mt-9">
            <SectionHeading>Responsive layouts</SectionHeading>
          </div>
          <Paragraph className={fontSizeOverides}>
            You focus on the message. Shorthand lets you add images, videos, and other media that render perfectly across all devices.
          </Paragraph>
        </div>
        <div data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease-out">
          <div className="w-full tw-aspect-[3/2] tw-overflow-hidden tw-rounded-[16px] max-md:tw-h-auto">
            <StaticImage
              src="../../../static/media/home/feature3.png"
              className="tw-h-full tw-w-full"
              alt="Create content with your brand's colour palette and styles in the Shorthand editor"
              aria-hidden="true"
              placeholder="blurred"
              width={750}
              height={500}
              objectFit="contain"
            />
          </div>
          <div className="!tw-mb-4 !tw-mt-9">
            <SectionHeading>On brand</SectionHeading>
          </div>
          <Paragraph className={fontSizeOverides}>Custom themes ensure your fonts, colours, and logos are always on brand.</Paragraph>
        </div>
      </div>
    </section>
  );
};
