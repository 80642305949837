import "../styles/index.scss";
import { Header } from "./Header";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export interface IMainTemplateProps {
  children?: React.ReactNode;
  location?: Record<any, string>;
}

export const DEFAULT_SEO = {
  title: "Start creating stunning visual web content for free | Shorthand",
  description: "Stunning visual web content, editorials, reports, proposals & more — no web design skills required.",
  image: "/media/seo/og_image_default.png",
  ogImage: "/media/seo/og_image_default.png",
  ogTitle: "Start creating stunning visual web content for free | Shorthand",
  ogDescription: "Stunning visual web content, editorials, reports, proposals & more — no web design skills required.",
  twitter: "Stunning visual web content, editorials, reports, proposals & more — no web design skills required.",
};

export function getMetaName(prop: string): string {
  switch (prop) {
    case "title":
      return prop;
    case "description":
      return prop;
    case "image":
      return "image";
    case "ogImage":
      return "og:image";
    case "ogVideo":
      return "og:video";
    case "ogVideoWidth":
      return "og:video:width";
    case "ogVideoHeight":
      return "og:video:height";
    case "ogVideoMimeType":
      return "og:video:type";
    case "ogTitle":
      return "og:title";
    case "ogDescription":
      return "og:description";
    case "twitter":
      return "twitter:description";
    default:
      return prop;
  }
}

export function getMetaFields(edge) {
  return [
    getMetaField("description", edge),
    getMetaField("ogVideo", edge),
    getMetaField("ogVideoWidth", edge),
    getMetaField("ogVideoHeight", edge),
    getMetaField("ogVideoMimeType", edge),
    getMetaField("image", edge, "https://shorthand.com"),
    getMetaField("ogImage", edge, "https://shorthand.com"),
    getMetaField("ogTitle", edge),
    getMetaField("ogDescription", edge),
    getMetaField("twitter", edge),
  ];
}

export function getMetaField(
  prop: string,
  edge: any,
  prefix: string = ""
): {
  name: string;
  property: string;
  content: string;
} {
  if (edge?.node[prop] && edge.node[prop] !== "") {
    return {
      name: getMetaName(prop),
      property: getMetaName(prop),
      content: `${prefix}${edge.node[prop]}`,
    };
  }
  return {
    name: getMetaName(prop),
    property: getMetaName(prop),
    content: `${prefix}${DEFAULT_SEO[prop]}`,
  };
}

export function getEdge(edges: any[], pathname: string): any {
  return edges.find(edge => edge.node.pathname === pathname);
}

export const TemplatesLayout: React.FC<IMainTemplateProps> = ({ children, location }) => {
  console.log("rendering TemplatesLayout");
  const data = useStaticQuery(graphql`
    query templatesLayoutQuery {
      site {
        siteMetadata {
          name
          title
          tagline
          description
        }
      }
      allTemplatesSeoJson {
        edges {
          node {
            pathname
            title
            description
            image
            ogImage
            ogVideo
            ogVideoWidth
            ogVideoHeight
            ogVideoMimeType
            ogTitle
            ogDescription
            twitter
          }
        }
      }
    }
  `);
  const edge = getEdge(data.allTemplatesSeoJson.edges, location.pathname);

  let meta = getMetaFields(edge);

  const undefinedOgVideoTags = ["og:video", "og:video:width", "og:video:height", "og:video:type"];
  const isAnyOgVideoTagUndefined = meta.some(edge => undefinedOgVideoTags.includes(edge.name) && edge.content === "undefined");

  if (isAnyOgVideoTagUndefined) {
    meta = meta.filter(edge => !["og:video", "og:video:width", "og:video:height", "og:video:type"].includes(edge.name));
  }

  return (
    <div>
      <Helmet title={getMetaField("title", edge).content} meta={meta} />
      <Header theme="dark-theme" />
      {children}
    </div>
  );
};
