import { Enquiry } from "../components/Enquiry";
import { Heading } from "../components/Heading/Heading";
import { SharedLayout, TemplateTile } from "../components/Templates";
import { TemplatesLayout } from "../components/TemplatesLayout";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

interface ITemplatesProps {
  location: Record<any, string>;
  data: Record<any, any>;
}

const Templates = ({ data: { allTemplateGalleryJson }, location }: ITemplatesProps) => {
  const [showPortal, setShowPortal] = React.useState(false);

  const portalProps = {
    type: "general-enquiry",
    subject: "general-enquiry",
    source: "marketing/home",
    onClose: () => setShowPortal(false),
  };

  const bodyStyles = "body-shorthand body-marketing body-marketing-templates body-light hide-elevio";
  const fullWidthStyles = "tw-flex tw-flex-wrap !tw-w-[100%] !tw-justify-center !tw-pb-[97px] !tw-pt-[78px] max-sm:!tw-pt-4";
  const columnStyles =
    "max-sm:!tw-w-[83%] max-container:!tw-w-[64%] max-xxxxl:!tw-w-[64%] min-xxxxl:!tw-w-[64%] tw-flex tw-flex-col text-center justify-center";
  const containerStyles =
    "!tw-w-[100%] relative z-2 tw-flex max-sm:!tw-pt-[26px] max-container:!tw-pt-[100px] max-xxxxl:!tw-pt-[100px]  min-xxxxl:!tw-pt-[100px] tw-justify-center !tw-flex-col !tw-items-center tw-bg-[#000]";

  return (
    <TemplatesLayout location={location}>
      <main style={{ zIndex: 2, position: "relative" }} className="template-page-fadein">
        {showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
        <Helmet
          bodyAttributes={{
            class: bodyStyles,
          }}
        />
        <section className={containerStyles}>
          <div className={columnStyles}>
            <br />
            <StaticImage
              src="../../static/media/logos/logo-white.svg"
              alt=""
              aria-hidden="true"
              placeholder="blurred"
              width={198}
              height={150}
              className="tw-mb-8 !tw-hidden tw-h-[65px] max-lg:!tw-block"
              objectFit="contain"
            />

            <Heading className="tw-mt-8" headerType={1} color="!tw-text-white">
              Shorthand templates
            </Heading>
            <Heading className="tw-mt-4" headerType={2} color="!tw-text-white" size="max-sm:!tw-text-xl">
              Accelerate your visual storytelling
            </Heading>
          </div>
          <div className={columnStyles}>
            <div className={fullWidthStyles}>
              <ul className="tw-mb-0  tw-grid tw-grid-cols-1 tw-gap-5 min-lg:!tw-grid-cols-3 min-md:tw-grid-cols-2">
                {allTemplateGalleryJson.nodes.map(item => (
                  <TemplateTile item={item} key={item.page} theme="dark" />
                ))}
              </ul>
            </div>
          </div>
        </section>
        <SharedLayout theme="dark-theme" />
      </main>
    </TemplatesLayout>
  );
};

export default Templates;

export const query = graphql`
  query MyQuery {
    allTemplateGalleryJson {
      nodes {
        templateId
        page
        heading
        related
        images {
          tileImage {
            alt
            src {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;
