import { RadixSelect } from "../PricingPageSelect/Select";
import * as styles from "./SelectSection.module.scss";
import * as React from "react";

export type Option = { label: string; value: string };

interface SelectSectionProps {
  setSelectedOption: (string) => void;
  selectedOption: Option;
  options: Option[];
  label: string;
  labelPosition: string;
  ariaLabel: string;
}

export const SelectSection = (props: SelectSectionProps): JSX.Element => {
  const { setSelectedOption, selectedOption, options, label, labelPosition, ariaLabel } = props;

  return (
    <div className={styles.selectSection}>
      <label htmlFor={ariaLabel} className={styles.selectSectionLabel} style={{ position: "relative", top: `${labelPosition}` }}>
        {label}
      </label>
      <RadixSelect
        {...props}
        options={options}
        value={selectedOption}
        onValueChange={value => {
          setSelectedOption(options.find(option => option.value === value));
        }}
      />
    </div>
  );
};
