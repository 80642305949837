import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Sunnmørsposten
            </h1>
            <h2>How Shorthand helped Sunnmørsposten put visual storytelling power back into the hands of its journalists</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-smp-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              The case for telling stories in a more visual, immersive way is well established at Sunnmørsposten in Norway.
            </h3>

            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-smp.jpg"
                alt="Typisk Ålesund, by Sunnmørsposten, renders responsively across all devices"
              />
            </div>

            <p>
              Before using Shorthand the news outlet had set up 'smpLab' – a three-person team comprising a journalist, designer and
              developer – to experiment with new formats. This included stories in a similar style to those created with Shorthand:
              full-screen, visually-rich and immersive. And they knew it was a format that worked in terms of engagement, with
              increases in reading time and higher social sharing stats.
            </p>
            <p>But there was a big issue that remained. Time.</p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “Shorthand has untied a lot of my time to focus on innovation, and leave the 'regular' storytelling to the
                  journalist”.
                </p>
              </blockquote>
              <figcaption className="quote-cite">Liv Håker</figcaption>
            </figure>

            <p>
              “The stories took us days and weeks to develop,” Liv Håker, head of digital development at Sunnmørsposten, remembers.
              “And since it took that long, not many journalists outside our team dared to begin projects together with us.”
            </p>

            <p>
              “We really needed to simplify the process, to open this kind of storytelling to our whole team of journalists. And then
              we found Shorthand - perfect!”
            </p>

            <p>
              The visual-storytelling app is now used by journalists and designers across news, culture and 'weekend' at
              Sunnmørsposten, with plans for even wider use moving forward. The projects also benefit from the input of a developer (if
              making use of the extra developer tools in Shorthand) and an editor.
            </p>

            <p>
              “It has lifted our ability to tell stories online in a new, better and more engaging way,” Liv explained. In 2016 one of
              their Shorthand stories –{" "}
              <a href="https://www.smp.no/nyheter/article11469238.ece" target="_blank" rel="noopener noreferrer" className="btn-link">
                Fjellet som trugar
              </a>{" "}
              – was highly commended at an awards event held by parent company Polaris Media, in recognition of its balanced use of
              visuals to tell the story, which included powerful use of the Reveal effect in Shorthand to highlight the risk of
              landslide.
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-smp-sml-01.jpg"
                alt="A section from one of Sunnmørsposten's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-smp-sml-02.jpg"
                alt="A section from one of Sunnmørsposten's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-smp-sml-04.jpg"
                alt="A section from one of Sunnmørsposten's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-smp-sml-03.jpg"
                alt="A section from one of Sunnmørsposten's stories"
              />
            </div>
            <div className="caption">A selection of sections from Sunnmørsposten's stories</div>

            <p>
              According to Liv “the statistics on the story are through the roof” with around 50,000 pageviews (36,000 uniques) and a
              dwell time of almost five minutes.
            </p>
            <p>
              "I used Shorthand to tell the story because I knew it would fit perfectly, and I wanted to show colleagues that the tool
              could do the same as we had done before (with coding) – and more.”
            </p>
            <p>
              And at the same time they have significantly cut down the time required to create the stories, from a team of three –
              including a journalist, designer and developer – taking around two weeks to pull together a project, to a couple of days
              work for a single journalist. This means they could continue to enjoy the boosts to engagement, without having to invest
              huge amounts of time.
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “On average Shorthand stories score higher on traffic, engagement/time spent on story and social activity than other
                  paid stories on our site”.
                </p>
              </blockquote>
              <figcaption className="quote-cite">Liv Håker</figcaption>
            </figure>

            <p>
              “We already knew that our readers engage more deeply in this kind of storytelling, and we see the trend continuing when
              using Shorthand too,” Liv said.
            </p>
            <p>
              “On average Shorthand stories score higher on traffic, engagement/time spent on story and social activity than other paid
              stories on our site.”
            </p>
            <p>
              And it's not only making a difference to the time and engagement stats. For Liv personally it has also enabled her to
              stay focused on innovation – a key part of her role leading the digital development of the organisation – as the
              journalists no longer require the technical input they did before.
            </p>
            <p>
              Now instead, they can create visually-compelling narratives themselves, with a simple-to-use editor and intuitive
              building process.
            </p>
            <p>
              “It's so easy to create stories in,” Liv added. “I love the fact that I can tell the most tech-scared journalists: "it's
              just like making a Powerpoint - only better”.
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "sunnmorsposten")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query sunnmorspostenQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
