import { IBillingCycle } from "../PricingPlans";
import { BillingPeriods } from "./BillingPeriods/BillingPeriods";
import { Byline } from "./ByLine/ByLine";
import { ColorBar } from "./ColorBar/ColorBar";
import { FormLink } from "./FormLink/FormLink";
import * as styles from "./PricingPlan.module.scss";
import { Title } from "./Title/Title";
import * as React from "react";
import { IPlan } from "src/lib/src/common/plans";

let classNames = require("classnames");

export interface PricingPlanProps {
  /**
   * any discount available to the plan
   */
  showDiscount: boolean;
  /**
   * any discount available to the plan
   */
  discount: string;
  /**
   * the billing period
   */
  period: IBillingCycle;
  /**
   * all plan details
   */
  plan: IPlan;
  /**
   * text to go in the cta button
   */
  ctaText: string;
  /**
   * includes a contact form link
   */
  formLink?: { text: string; ctaFunction: () => void };
  /**
   * the current plan the user is on
   */
  isCurrentPlan?: boolean;
  /**
   * used style a plan light or dark
   */
  theme: "light" | "dark";
  /**
   * used style a plan if it is featured
   */
  isFeaturePlan?: boolean;
  /**
   * the function the Cta button calls
   */
  ctaFunction?: (planId: string) => void;
  /**
   * the function the swtich  component calls
   */
  switchFunction?: (value: boolean) => void;
  children?: React.ReactNode;
}

export const PricingPlan = (props: PricingPlanProps): JSX.Element => {
  const {
    plan,
    period,
    showDiscount,
    discount,
    isFeaturePlan,
    ctaText,
    formLink,
    isCurrentPlan,
    theme,
    ctaFunction,
    switchFunction,
    children,
  } = props;
  const { name, marketing } = plan;

  function handleCtaClicked() {
    ctaFunction(plan.id);
  }

  return (
    <div className={classNames(styles.plan, { [styles.planDark]: theme === "dark" }, { [styles.planLight]: theme === "light" })}>
      <Title title={name} theme={theme} />
      <ColorBar id={plan.id} />
      <BillingPeriods
        showDiscount={showDiscount}
        planName={plan.name}
        period={period}
        discount={discount}
        handleFunction={switchFunction}
      />
      <Byline text={marketing.label} theme={theme} />
      {children}
      <button
        onClick={handleCtaClicked}
        data-testid="plan-cta"
        className={classNames(
          { [styles.btnWhite]: isFeaturePlan },
          {
            "btn btn-secondary btn-inverse btn-lgbtn btn-secondary btn-inverse btn-lg !tw-m-0 !tw-font-bold max-xl:!tw-text-lg max-sm:!tw-text-sm":
              !isCurrentPlan,
          },
          { [styles.btnSecondary]: isCurrentPlan }
        )}
      >
        {ctaText}
      </button>
      {formLink && <FormLink formLink={formLink} />}
    </div>
  );
};
