import { useIsMobile } from "../../../hooks/useIsMobile";
import { Option, SelectSection } from "../SelectSection/SelectSection";
import { Cell } from "./Cell";
import { CtaCell } from "./CtaCell";
import * as styles from "./FeaturesMatrix.module.scss";
import { FeaturesTable } from "./FeaturesTable";
import { HeaderCell } from "./HeaderCell";
import { LabelCell } from "./LabelCell";
import { LabelHeaderCell } from "./LabelHeaderCell";
import {
  Feature,
  adminSecurityFeatures,
  analyticsFeatures,
  brandFeatures,
  collaborationFeatures,
  customerSuccessFeatures,
  publishingFeatures,
  storyFeatures,
  technicalSupportFeatures,
} from "./allPlanFeatures";
import { createColumnHelper } from "@tanstack/react-table";
import * as React from "react";

type FeaturesMatrixProps = {
  handleEnterpriseClick: () => void;
  handleTeamClick: () => void;
  handleBusinessClick: () => void;
  handleStarterClick: () => void;
};

const plans = [
  { label: "Starter", value: "Starter" },
  { label: "Team", value: "Team" },
  { label: "Business", value: "Business" },
  { label: "Enterprise", value: "Enterprise" },
];

export const FeaturesMatrix = (props: FeaturesMatrixProps): JSX.Element => {
  const { handleBusinessClick, handleEnterpriseClick, handleTeamClick, handleStarterClick } = props;
  const [selectedPlan, setSelectedPlan] = React.useState<{ label: string; value: string }>({ label: "Starter", value: "Starter" });
  const columnHelper = React.useMemo(() => createColumnHelper<Feature>(), []);

  const isMobile = useIsMobile(920);

  const sharedColumns = [
    columnHelper.accessor(row => row.starter, {
      id: "starter",
      header: () => <HeaderCell cellValue="Starter" />,
      cell: info => <Cell cellValue={info.getValue()} planName="starter" />,
      footer: () => <></>,
    }),
    columnHelper.accessor(row => row.team, {
      id: "team",
      header: () => <HeaderCell cellValue="Team" />,
      cell: info => <Cell cellValue={info.getValue()} planName="team" />,
    }),
    columnHelper.accessor(row => row.business, {
      id: "business",
      header: () => <HeaderCell cellValue="Business" />,
      cell: info => <Cell cellValue={info.getValue()} planName="business" />,
    }),
    columnHelper.accessor(row => row.enterprise, {
      id: "enterprise",
      header: () => <HeaderCell cellValue="Enterprise" />,
      cell: info => <Cell cellValue={info.getValue()} planName="enterprise" />,
    }),
  ];

  const storyFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Story design & media" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const brandFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Brand" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const publishingFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Publishing" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const analyticsFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Analytics & Tag managers" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const collaborationFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Collaboration" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const technicalSupportFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Technical support & services" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const adminSecurityFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Admin & Security" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const customerSuccessFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <LabelHeaderCell cellValue="Customer success" />,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    columnHelper.accessor(row => row.starter, {
      id: "starter",
      header: () => <HeaderCell cellValue="Starter" />,
      cell: info => <Cell cellValue={info.getValue()} planName="starter" />,
      footer: () => <CtaCell handleFunction={handleStarterClick} ctaText="Get started" />,
    }),
    columnHelper.accessor(row => row.team, {
      id: "team",
      header: () => <HeaderCell cellValue="Team" />,
      cell: info => <Cell cellValue={info.getValue()} planName="team" />,
      footer: () => <CtaCell handleFunction={handleTeamClick} ctaText="Request free trial" />,
    }),
    columnHelper.accessor(row => row.business, {
      id: "business",
      header: () => <HeaderCell cellValue="Business" />,
      cell: info => <Cell cellValue={info.getValue()} planName="business" />,
      footer: () => <CtaCell handleFunction={handleBusinessClick} ctaText="Request free trial" />,
    }),
    columnHelper.accessor(row => row.enterprise, {
      id: "enterprise",
      header: () => <HeaderCell cellValue="Enterprise" />,
      cell: info => <Cell cellValue={info.getValue()} planName="enterprise" />,
      footer: () => <CtaCell handleFunction={handleEnterpriseClick} ctaText="Contact sales" />,
    }),
  ];

  const memoDesktopTableUi = React.useMemo(() => {
    return (
      <div className="tw-animate-fadeIn">
        <FeaturesTable columns={storyFeaturesColumns} tableData={storyFeatures} />
        <FeaturesTable columns={brandFeaturesColumns} tableData={brandFeatures} />
        <FeaturesTable columns={publishingFeaturesColumns} tableData={publishingFeatures} />
        <FeaturesTable columns={collaborationFeaturesColumns} tableData={collaborationFeatures} />
        <FeaturesTable columns={analyticsFeaturesColumns} tableData={analyticsFeatures} />
        <FeaturesTable columns={adminSecurityFeaturesColumns} tableData={adminSecurityFeatures} />
        <FeaturesTable columns={technicalSupportFeaturesColumns} tableData={technicalSupportFeatures} />
        <FeaturesTable columns={customerSuccessFeaturesColumns} tableData={customerSuccessFeatures} />
      </div>
    );
  }, [storyFeatures]);

  function handleSelectedOption(option: Option) {
    setSelectedPlan(option);
  }

  const memoMobileTableUi = React.useMemo(() => {
    const mobileColumnVisbility = {
      label: true,
      starter: selectedPlan.value === "Starter",
      team: selectedPlan.value === "Team",
      business: selectedPlan.value === "Business",
      enterprise: selectedPlan.value === "Enterprise",
    };
    return (
      <div className="tw-animate-fadeIn">
        <FeaturesTable columns={storyFeaturesColumns} tableData={storyFeatures} columnVisibility={mobileColumnVisbility} />
        <FeaturesTable columns={brandFeaturesColumns} tableData={brandFeatures} columnVisibility={mobileColumnVisbility} />
        <FeaturesTable columns={publishingFeaturesColumns} tableData={publishingFeatures} columnVisibility={mobileColumnVisbility} />
        <FeaturesTable
          columns={collaborationFeaturesColumns}
          tableData={collaborationFeatures}
          columnVisibility={mobileColumnVisbility}
        />
        <FeaturesTable columns={analyticsFeaturesColumns} tableData={analyticsFeatures} columnVisibility={mobileColumnVisbility} />
        <FeaturesTable
          columns={adminSecurityFeaturesColumns}
          tableData={adminSecurityFeatures}
          columnVisibility={mobileColumnVisbility}
        />
        <FeaturesTable
          columns={technicalSupportFeaturesColumns}
          tableData={technicalSupportFeatures}
          columnVisibility={mobileColumnVisbility}
        />
        <FeaturesTable
          columns={customerSuccessFeaturesColumns}
          tableData={customerSuccessFeatures}
          columnVisibility={mobileColumnVisbility}
        />
      </div>
    );
  }, [selectedPlan]);

  return (
    <>
      {isMobile ? (
        <div className={styles.featuresMatrixMobile}>
          <div
            className="tw-sticky tw-top-0 tw-z-50 tw-flex tw-w-full tw-items-center tw-bg-black tw-p-4"
            style={{ position: "-webkit-sticky" }}
          >
            <SelectSection
              labelPosition="7.5px"
              ariaLabel="featuresSelect"
              label="Features for plan"
              options={plans}
              setSelectedOption={handleSelectedOption}
              selectedOption={selectedPlan}
            />
          </div>
          {memoMobileTableUi}
        </div>
      ) : (
        <div className={styles.featuresMatrixDesktop}>{memoDesktopTableUi}</div>
      )}
    </>
  );
};
