import { Footer } from "../Footer";
import { SignupCTAAlt } from "../Homepage/SignupCTAAlt";
import { Theme } from "../NavBar";
import { TemplatesFAQ } from "./";
import * as React from "react";

export const SharedLayout = (props: Theme) => {
  const theme = props.theme;

  const classes = theme === "dark-theme" ? "tw-bg-black" : "tw-bg-white";

  return (
    <div className={classes}>
      <TemplatesFAQ theme={theme} />
      <section className="section section-end-cta-dark">
        <SignupCTAAlt />
        <Footer className="footer-dark" />
      </section>
    </div>
  );
};
